<template>
  <div id="app" class="container">
    <h1>Random Website Generator</h1>
    <button @click="generateRandomLink">Next Link</button>
    <div v-if="randomLink" class="link">
      <p>{{ randomLink }}</p>
      <button @click="visitLink">Visit Link</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      randomLink: null,
      websites: [
        "https://www.example.com",
        "https://www.wikipedia.org",
        "https://www.github.com",
        "https://www.stackoverflow.com",
        "https://www.reddit.com"
      ],
    };
  },
  methods: {
    generateRandomLink() {
      const randomIndex = Math.floor(Math.random() * this.websites.length);
      this.randomLink = this.websites[randomIndex];
    },
    visitLink() {
      if (this.randomLink) {
        window.open(this.randomLink, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  margin-top: 50px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 5px;
}

.link {
  margin-top: 20px;
}

p {
  font-size: 20px;
}

a {
  color: #42b983;
  text-decoration: none;
  font-size: 20px;
}
</style>